import { useContext, useState, FC, ReactNode, Suspense, Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import GetHelpModalSellerLazy from 'dibs-contact-1stdibs/exports/GetHelpModalSellerLazy';
import { Link } from 'dibs-elements/exports/Link';
import classnames from 'classnames';
import styles from './styles/CustomHeaderMessage.scss';
import getSellerSupportLink from 'dibs-support-link/exports/getSellerSupportLink';
import { UserContext } from '../UserContext';
import { useCanShowLiveChat } from '../helpers/useCanShowLiveChat';

const NeedAssistance: FC<{ setIsModalOpen: Dispatch<SetStateAction<boolean>> }> = ({
    setIsModalOpen,
}) => {
    const canShowLiveChat = useCanShowLiveChat();

    if (canShowLiveChat) {
        return null;
    }

    return (
        <>
            <span className={styles.prompt}>
                <FormattedMessage
                    id="dealerHeader.needAssistance"
                    defaultMessage="Need Assistance?"
                />
            </span>
            <div className={styles.linkContainer}>
                <Link className={styles.supportLink} href={getSellerSupportLink('')}>
                    <FormattedMessage
                        id="dealerHeader.supportLink"
                        defaultMessage="Support Center"
                    />
                </Link>
                <a className={styles.supportLink} onClick={() => setIsModalOpen(true)}>
                    <FormattedMessage
                        id="dealerHeader.contactLink"
                        defaultMessage="Contact Support"
                    />
                </a>
            </div>
        </>
    );
};

type Props = {
    children: ReactNode;
};

export const CustomHeaderMessage: FC<Props> = ({ children }) => {
    const { userOrSeller } = useContext(UserContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (
        <Suspense fallback={null}>
            <GetHelpModalSellerLazy
                placement="dealerGlobalNav"
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                topicValue="accountSettings"
            />
            <div
                className={classnames(styles.customHeaderMessage, {
                    [styles.separators]: userOrSeller,
                })}
            >
                <h1 data-tn="customBannerText" className={styles.bannerText}>
                    {children}
                </h1>
                <div className={styles.assistance}>
                    <Suspense fallback={null}>
                        <NeedAssistance setIsModalOpen={setIsModalOpen} />
                    </Suspense>
                </div>
            </div>
        </Suspense>
    );
};
