/**
 * @generated SignedSource<<15d37e4c69b28a4abf80d4dece2a8151>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavBarButtons_buttons$data = ReadonlyArray<{
  readonly eventName: string | null;
  readonly href: string | null;
  readonly icon: string | null;
  readonly id: string | null;
  readonly interactionType: string | null;
  readonly shortText: string | null;
  readonly text: string | null;
  readonly " $fragmentType": "NavBarButtons_buttons";
}>;
export type NavBarButtons_buttons$key = ReadonlyArray<{
  readonly " $data"?: NavBarButtons_buttons$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavBarButtons_buttons">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "NavBarButtons_buttons",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "href",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "interactionType",
      "storageKey": null
    }
  ],
  "type": "NavBarButton",
  "abstractKey": null
};

(node as any).hash = "52308a36bd89e62c030acd365f97f0bc";

export default node;
