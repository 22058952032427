import { FC, useEffect } from 'react';
import serverVars from 'server-vars';
import classnames from 'classnames';
import dibsCss from 'dibs-css';

type SendFeedbackModalProps = {
    feedBackSurveyUrl: string;
    isVisible: boolean;
    onSubmit?: () => void;
    additionalParams?: Record<string, string>;
    className?: string;
};

const EVENT_TYPE = 'message';

export const SendFeedbackWindow: FC<SendFeedbackModalProps> = ({
    feedBackSurveyUrl,
    isVisible,
    onSubmit,
    additionalParams,
    className = classnames(dibsCss.wFull, dibsCss.h250px, dibsCss.border0),
}) => {
    const sellerId = serverVars.get('sellerData.id');
    const pageType = serverVars.get('tracking.pageType');
    const params = new URLSearchParams({
        sellerId,
        pageType,
        ...additionalParams,
    });

    useEffect(() => {
        const onSurveySubmit = (event: MessageEvent): void => {
            const isFeedbackSubmitted =
                event.origin?.includes('getfeedback') && event.data?.includes('submittedResponse');

            if (isFeedbackSubmitted && onSubmit) {
                onSubmit();
            }
        };

        if (isVisible && onSubmit) {
            window.addEventListener(EVENT_TYPE, onSurveySubmit);
        }

        return () => {
            if (onSubmit) {
                window.removeEventListener(EVENT_TYPE, onSurveySubmit);
            }
        };
    }, [isVisible, onSubmit]);

    return (
        <iframe
            className={className}
            src={`${feedBackSurveyUrl}?gf_footer_background_off&gf_hide_progress&${params.toString()}`}
            // Can't be added to class as it's iframe and used for loading skeleton
            style={{ backgroundColor: '#E3E3E3' }}
        />
    );
};
