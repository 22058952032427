import { graphql, useLazyLoadQuery } from 'react-relay';
import SV from 'server-vars';

import { getAbTestV2 } from 'dibs-ab-tests/exports/clientAbTestV2';
import { getSellerId } from 'dibs-cookie-jar';

import { useCanShowLiveChatQuery } from './__generated__/useCanShowLiveChatQuery.graphql';

const query = graphql`
    query useCanShowLiveChatQuery(
        $fetchConfig: Boolean!
        $sellerId: String!
        $fetchSeller: Boolean!
    ) {
        viewer @include(if: $fetchConfig) {
            liveChatConfig(touchPoint: Seller_OnBoarding) {
                isAgentAvailable
                isChatEnabled
            }
            seller(sellerId: $sellerId) @include(if: $fetchSeller) {
                isPendingUnpaid
            }
        }
    }
`;

const sellerId = SV.get('sellerData.serviceId') || getSellerId(document.cookie);

export const useCanShowLiveChat = (): boolean => {
    const pathname = window.location.pathname;
    const isSellerOnboardingPage = pathname.includes('/dealers/account-setup/');
    const isItemUploadPage = pathname.includes('/dealers/item/');
    const isSellerOnboardingChatVariant =
        getAbTestV2('Seller-Onboarding-Chat')?.variant === 'variant';
    const fetchSeller = isItemUploadPage && !!sellerId && isSellerOnboardingChatVariant;
    const fetchConfig = fetchSeller || (isSellerOnboardingPage && isSellerOnboardingChatVariant);

    const { viewer } = useLazyLoadQuery<useCanShowLiveChatQuery>(query, {
        fetchConfig,
        fetchSeller,
        sellerId,
    });
    const { seller, liveChatConfig } = viewer || {};
    const { isAgentAvailable, isChatEnabled } = liveChatConfig || {};

    if (!isSellerOnboardingChatVariant) {
        return false;
    }

    if (isItemUploadPage && !seller?.isPendingUnpaid) {
        return false;
    }

    return !!isAgentAvailable && !!isChatEnabled;
};
