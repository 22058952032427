// dibs-css must be imported before any packages that import dibs-css to guarantee dibs-css is
// bundled correctly in DAL.
import 'dibs-css/exports/dibs-css.module.css';

import serverVars from 'server-vars';
import { IntlProvider } from 'dibs-react-intl';
import { createRoot } from 'react-dom/client';
import NavHeader from './components/NavHeader';
import { QueryRenderer, graphql } from 'react-relay/legacy';
import { getRelayEnvironment } from '../clientRelayEnvironment';

import { internalQuery } from './__generated__/internalQuery.graphql';

import '../common';
import '../../scss/entries/internal.scss';

const navDomElement = document.getElementById('dibs-nav');
const root = createRoot(navDomElement as HTMLElement);

function renderNavbar(): void {
    const id = serverVars.get('userData.id');
    if (id) {
        root.render(
            <IntlProvider locale="en-US">
                <QueryRenderer<internalQuery>
                    query={graphql`
                        query internalQuery(
                            $userId: String!
                            $hasSellerPk: Boolean = false
                            $sellerPk: String = ""
                            $dotComHost: String = ""
                            $hyperwalletLink: String = ""
                            $customBannerText: String = ""
                            $renderNotifications: Boolean = false
                            $isDealer: Boolean = false
                        ) {
                            viewer {
                                ...NavHeader_viewer
                            }
                        }
                    `}
                    variables={{ userId: id }}
                    environment={getRelayEnvironment('internal')}
                    render={({ props }) => {
                        const viewer = props?.viewer;
                        return <NavHeader refetchIndex={0} viewer={viewer || null} />;
                    }}
                />
            </IntlProvider>
        );
    }
}

if (navDomElement) {
    renderNavbar();
}
